import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import TypeIcon from 'components/toast/TypeIcon';
import Typography from 'components/typography';

import { useStyles } from './styles';

const Toast = ({ id, message, type, duration = 3000, onClose }) => {
  const { toast } = useStyles();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const onRemove = useCallback(
    id => {
      setTimeout(() => {
        onClose(id);
      }, 400);
    },
    [onClose],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      onRemove(id);
      setVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onRemove, id]);

  return (
    <div className={clsx(toast, { visible, [type]: type })}>
      <TypeIcon type={type} />
      <Typography variant="bodyM">{message}</Typography>
    </div>
  );
};

export default Toast;
