import { lazy } from 'react';

import { withSuspense } from 'utils';

export const privateRoutes = [
  {
    path: '/',
    Component: withSuspense(lazy(() => import('pages/main'))),
  },
];

export const routes = [
  {
    path: '/signup',
    Component: withSuspense(lazy(() => import('pages/registration'))),
  },
  {
    path: '/signin',
    Component: withSuspense(lazy(() => import('pages/login'))),
  },
  {
    path: '/forgot-password',
    Component: withSuspense(lazy(() => import('pages/forgotPassword'))),
  },
  {
    path: '/reset-password',
    Component: withSuspense(lazy(() => import('pages/resetPassword'))),
  },
  {
    path: '/components',
    Component: withSuspense(lazy(() => import('pages/components'))),
  },
  {
    path: '/privacy',
    Component: withSuspense(lazy(() => import('pages/privacy'))),
  },
  {
    path: '/user-agreement',
    Component: withSuspense(lazy(() => import('pages/userAgreement'))),
  },
  {
    path: '/legal-conditions',
    Component: withSuspense(lazy(() => import('pages/legalConditions'))),
  },
  {
    path: '/verification',
    Component: withSuspense(lazy(() => import('pages/verification'))),
  },
];
