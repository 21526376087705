const setCookie = (name, value, days, withDomain) => {
  const stringValue = JSON.stringify(value);
  let expires,
    domain = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  if (withDomain) {
    domain = '; domain=' + process.env.REACT_APP_DOMAIN;
  }

  document.cookie = name + '=' + (stringValue || '') + expires + domain + '; path=/';
};

const getCookie = name => {
  const nameEQ = name + '=';
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    let cookieValue = cookies[i];

    while (cookieValue.charAt(0) === ' ') {
      cookieValue = cookieValue.substring(1, cookieValue.length);
    }

    if (cookieValue.indexOf(nameEQ) === 0) {
      return JSON.parse(cookieValue.substring(nameEQ.length, cookieValue.length));
    }
  }

  return null;
};

const eraseCookie = name => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export { setCookie, getCookie, eraseCookie };
