import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ThemeWrapper } from 'config/theme';
import { store } from 'store';
import { TranslatorProvider } from 'utils/translator';

import './index.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URI + '/graphql',
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <TranslatorProvider>
        <ThemeWrapper>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeWrapper>
      </TranslatorProvider>
    </Provider>
  </ApolloProvider>,
);
