export const colors = {
  blue: {
    5: '#080b1a',
    10: '#101733',
    20: '#1f2d66',
    30: '#2f4499',
    40: '#4E71FF',
    50: '#6986ff',
    60: '#B8C6FF',
    70: '#DCE3FF',
    80: '#EDF1FF',
    90: '#F2F5FF',
    95: '#FAFBFF',
  },
  violet: {
    5: '#0C0914',
    10: '#171229',
    20: '#2F2352',
    30: '#46357A',
    40: '#7558CC',
    50: '#8A71D4',
    60: '#C8BCEB',
    70: '#E3DEF5',
    80: '#EEEBF9',
    90: '#F6F2FF',
    95: '#FBFAFE',
  },
  yellow: {
    5: '#0C0914',
    10: '#332A16',
    20: '#997F41',
    30: '#CCA957',
    40: '#FFD36D',
    50: '#FFDA83',
    60: '#FFEDC5',
    70: '#FFF6E2',
    80: '#FFFAED',
    90: '#FFFCF6',
    95: '#FFFEFB',
  },
  grey: {
    0: '#000000',
    5: '#0E0F11',
    10: '#17191C',
    20: '#22252A',
    30: '#383B45',
    40: '#5D636F',
    50: '#9096A2',
    60: '#A8ADBA',
    70: '#D3D7DE',
    80: '#E4E6EB',
    90: '#F3F3F5',
    95: '#F5F6FA',
    100: '#FFFFFF',
  },
  red: {
    5: '#180609',
    10: '#2F0C12',
    20: '#5F1824',
    30: '#8E2536',
    40: '#ED3D5A',
    50: '#F05A73',
    60: '#F8B1BD',
    70: '#FBD8DE',
    80: '#FDECEF',
    90: '#FEF5F7',
    95: '#FFFBFC',
  },
  green: {
    5: '#03130C',
    10: '#062718',
    20: '#0C4E30',
    30: '#127448',
    40: '#1EC278',
    50: '#40CB8C',
    60: '#A5E7C9',
    70: '#D2F3E4',
    80: '#E8F9F1',
    90: '#F4FCF8',
    95: '#FAFEFC',
  },
};
