export const EMAIL_VERIFICATION_STEP = 2;

export const PHONE_VERIFICATION_STEP = 4;

export const PERSON_VERIFICATION_STEP = 6;

export const EMAIL_VERIFICATION_CODE_LENGTH = 6;

export const PHONE_NUMBER_VERIFICATION_CODE_LENGTH = 6;

export const PHONE_NUMBER_PREFIX = '+1';

export const CLINIC = 'clinic';

export const selectDefaultOptions = [
  {
    label: 'Label 1',
    value: 'Value 1',
  },
  {
    label: 'Label 2',
    value: 'Value 2',
  },
  {
    label: 'Label 3',
    value: 'Value 3',
  },
];

export const NUMBER_REGEXP = /[0-9]/;
const UPPERCASE_REGEXP = /[A-Z]/;
const CHARACTER_LENGTH_REGEXP = /.{8,}/;
const SYMBOLS_REGEXP = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/;
export const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

export const PHONE_NUMBER_MASK = '+1 (999) 999 9999';

export const strengthRegexp = {
  uppercase: UPPERCASE_REGEXP,
  number: NUMBER_REGEXP,
  symbol: SYMBOLS_REGEXP,
  length: CHARACTER_LENGTH_REGEXP,
};

export const registrationDefaultState = {
  email: '',
  fullName: '',
  password: '',
  accountType: '',
  phoneNumber: PHONE_NUMBER_PREFIX,
};

export const loginDefaultState = {
  email: '',
  password: '',
  stayLoggedIn: false,
};

export const forgotPasswordDefaultState = {
  email: '',
  stayLoggedIn: false,
};

export const resetPasswordDefaultState = {
  password: '',
  confirmPassword: '',
};

export const STRENGTH_MAPPING = [
  { value: 'uppercase', text: 'uppercase' },
  { value: 'symbol', text: 'special_char' },
  { value: 'number', text: 'number' },
  { value: 'length', text: '8_letters' },
];

export const addressDefaultState = {
  street: '',
  apartment: '',
  city: '',
  stateCode: '',
  postalCode: '',
};

export const DATE_FORMAT = 'DD/MM/YYYY';
