import { memo } from 'react';
import clsx from 'clsx';

import { ReactComponent as SuccessIcon } from 'assets/icons/Success.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import { useStyles } from 'components/toast/styles';

const TypeIcon = ({ type }) => {
  const { type: typeClass, icon } = useStyles();
  const typeIcons = {
    success: <SuccessIcon />,
    error: <InfoIcon />,
  };

  return <div className={clsx(icon, typeClass)}>{typeIcons[type]}</div>;
};

export default memo(TypeIcon);
