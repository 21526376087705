import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(theme => ({
  root: {
    padding: '40px 0',
    marginTop: 'auto',
    textAlign: 'center',
    color: theme.palette.neutral,
    [theme.screen.sm]: {
      padding: '20px 0',
    },
  },
}));
