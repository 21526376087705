import { createSlice } from '@reduxjs/toolkit';

import { saveToStorage } from 'utils';

const initialState = {
  lang: 'en',
};

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setCurrentLanguage: (state, action) => {
      saveToStorage('lang', action.payload);
      state.lang = action.payload;
    },
  },
});

export const { setCurrentLanguage } = baseSlice.actions;

export default baseSlice.reducer;
