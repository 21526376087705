import React, { useCallback, useState } from 'react';

import Toast from './Toast';
import { useStyles } from './styles';

let toastIdCounter = 0;
let toastManager;

export const addToast = (type, message, duration) => {
  if (toastManager) {
    toastManager.addToast({ id: toastIdCounter++, message, type, duration });
  }
};

const ToastBar = () => {
  const { root } = useStyles();
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(({ id, message, type, duration }) => {
    setToasts(prevToasts => [...prevToasts, { id, message, type, duration }]);
  }, []);

  const removeToast = useCallback(id => {
    setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
  }, []);

  toastManager = { addToast };

  return (
    <div className={root}>
      {toasts.map(toast => (
        <Toast
          id={toast.id}
          key={toast.id}
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={removeToast}
        />
      ))}
    </div>
  );
};

export default ToastBar;
