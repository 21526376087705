import Typography from 'components/typography';
import { useTranslator } from 'utils/translator';

import { useStyles } from './styles';

function Footer() {
  const { t } = useTranslator();

  const { root } = useStyles();

  return (
    <footer className={root}>
      <div>
        <Typography variant="bodyM">{t('footer_text')}</Typography>
      </div>
    </footer>
  );
}

export default Footer;
