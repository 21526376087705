// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

a {
	text-decoration: none;
}

img {
	max-width: 100%;
	width: 100%;
}

.root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

main {
	flex: 1 1;
	display: flex;
}

.form-wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.container {
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
}

@media (max-width: 768px) {
	.form-wrapper {
		gap: 16px;
	}
}

.MuiPickersToolbar-root {
	display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;CACC,iCAAiC;CACjC,mCAAmC;CACnC,kCAAkC;AACnC;;AAEA,gCAAgC;AAChC;;CAEC,wBAAwB;CACxB,SAAS;AACV;;AAEA,YAAY;AACZ;CACC,0BAA0B;AAC3B;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,eAAe;CACf,WAAW;AACZ;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,iBAAiB;AAClB;;AAEA;CACC,SAAO;CACP,aAAa;AACd;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,SAAS;AACV;;AAEA;CACC,iBAAiB;CACjB,WAAW;CACX,cAAc;CACd,eAAe;AAChB;;AAEA;CACC;EACC,SAAS;CACV;AACD;;AAEA;CACC,wBAAwB;AACzB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');\n\nbody {\n\tfont-family: 'Roboto', sans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n\t-webkit-appearance: none;\n\tmargin: 0;\n}\n\n/* Firefox */\ninput[type='number'] {\n\t-moz-appearance: textfield;\n}\n\na {\n\ttext-decoration: none;\n}\n\nimg {\n\tmax-width: 100%;\n\twidth: 100%;\n}\n\n.root {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmin-height: 100vh;\n}\n\nmain {\n\tflex: 1;\n\tdisplay: flex;\n}\n\n.form-wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 24px;\n}\n\n.container {\n\tmax-width: 1100px;\n\twidth: 100%;\n\tmargin: 0 auto;\n\tpadding: 0 20px;\n}\n\n@media (max-width: 768px) {\n\t.form-wrapper {\n\t\tgap: 16px;\n\t}\n}\n\n.MuiPickersToolbar-root {\n\tdisplay: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
