import { memo } from 'react';
import clsx from 'clsx';

import { TypographyVariants } from 'constants/styles';

import { useStyles } from './styles';

const Typography = ({ as, status, children, variant, className, bold, ...restProps }) => {
  const Tag = as || TypographyVariants[variant];
  const styles = useStyles();

  return (
    <Tag className={clsx(className, styles[variant], { [styles.bold]: bold, [styles[status]]: status })} {...restProps}>
      {children}
    </Tag>
  );
};

export default memo(Typography);
