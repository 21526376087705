import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

const useForm = ({ schema, defaultState, runtimeValidate = true, callback }) => {
  const [formData, setFormData] = useState(defaultState);
  const [errors, setErrors] = useState({});

  const handleFormUpdate = useCallback(field => {
    setFormData(prev => ({ ...prev, ...field }));
    callback?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetError = useCallback(name => {
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  }, []);

  const validateField = useCallback(async (name, value) => {
    try {
      await yup.reach(schema, name).validate(value);
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
      return true;
    } catch (error) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateFields = useCallback(async () => {
    const result = await Promise.all(
      Object.entries(formData).map(async ([name, value]) => await validateField(name, value)),
    );

    return !result.some(value => !value);
  }, [formData, validateField]);

  useEffect(() => {
    if (runtimeValidate) {
      Object.entries(formData).forEach(([name, value]) => validateField(name, value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return { formData, errors, handleFormUpdate, validateField, validateFields, resetError };
};

export default useForm;
